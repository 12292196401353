<template>
  <div
    class="min-h-screen w-full overflow-hidden bg-amber-50 bg-gradient-to-r to-amber-100 dark:bg-slate-900 dark:from-slate-800 dark:text-slate-300"
  >
    <div class="flex h-screen flex-col">
      <div class="mt-10 h-full">
        <slot />
      </div>
    </div>
  </div>
</template>
